import React from "react"

// ・メールアドレス
// aleenta2020@gmail.com

// メールアドレスパスワード
// envExam

//recapthca
// envExam

//問い合せメール：顧客自動返信
//Title: お問い合わせありがとうございます｜AleentaRoyalResort

// <doctype html >
// <html lang="ja">
//   <head>
//     <meta charset="utf-8">
//     <title>The HTML5 Herald</title>
//   </head>
// <body>
// <table style="width:90%; max-width: 500px; margin: 20px auto; font-size: 15px;">
//   <tbody>
//     <tr>
//       <td style="margin-bottom:30px;">"cname"様</td>
//     </tr>
//     <tr>
//       <td>初めまして、アリンタロイヤルリゾートです。<br />この度はお問い合わせ頂き有難う御座います。ご返信については、弊サロン営業時間に担当者よりご連絡させて頂きます。何卒、よろしくお願い致します。<br />なお、このメールにご返信頂いてもご対応できかねますのでご了承ください。</td>
//     </tr>
//     <tr>
//       <td style="font-size:12px; color:gray;">個人情報保護のため、お問い合わせ内容は記載いたしません。</td>
//     </tr>
//     <tr>
//       <td style="padding-top: 30px; font-size: 1.32em;">Aleenta Royal Resort<br />アリンタロイヤルリゾート</td>
//     </tr>
//     <tr>
//       <td>東京都葛飾区亀有3-37-31　アビタシオン森1F</td>
//     </tr>
//     <tr>
//       <td>TEL: 03-6662-7281</td>
//     </tr>
//   </tbody>
// </table >
// </body>
//   </html>




//問い合せメール：運営者向けメール
//Title: 【WEB】ウェブサイトからのお問い合わせです

// <doctype html >
// <html lang="ja">
//   <head>
//     <meta charset="utf-8">
//     <title>The HTML5 Herald</title>
//   </head>
// <body>
// <table style="width: 90 %; max- width 500px; margin: 20px auto; font - size: 15px;">
//   <tbody>
//     <tr>
//       <td style="margin-bottom: 30px;">ご担当者様</td>
//     </tr>
//     <tr>
//       <td>ウェブサイトからのお問い合わせメールです。このメールに返信してもお客様には届きませんのでご注意ください！</td>
//     </tr>
//     <tr>
//       <td>
//         お名前　：　"cname"<br />
//           電話番号　：　"tel"<br />
//           メール　：　"mail"<br />
//           ご希望メニュー　： "menu"<br />
//           ご希望時間帯　： "time"
//         </td>
//     </tr>
//     <tr>
//       <td>お問い合わせ内容：</td>
//     </tr>
//     <tr>
//       <td>"inquiries"</td>
//     </tr>
//     <tr>
//       <td style="padding-top: 30px; text-align:right;">Swanoir LLC</td>
//     </tr>
//   </tbody>
// </table >
// </body>
//   </html>




const InquiryMail = () => (
  <table style={{ width: "90%", maxWidth: "500px", margin: "20px auto", fontSize: "15px" }}>
    <tbody>
      <tr>
        <td style={{ marginBottom: "30px" }}>ご担当者様</td>
      </tr>
      <tr>
        <td>ウェブサイトからのお問い合わせメールです。このメールに返信してもお客様には届きませんのでご注意ください！</td>
      </tr>
      <tr>
        <td>
          お名前　：　"cname"<br />
          電話番号　：　"tel"<br />
          メール　：　"mail"<br />
          ご希望メニュー　： "menu"<br />
          ご希望時間帯　： "time"
        </td>
      </tr>
      <tr>
        <td>お問い合わせ内容：</td>
      </tr>
      <tr>
        <td>"inquiries"</td>
      </tr>
      <tr>
        <td style={{ paddingTop: "30px", textAlign: "right" }}>Swanoir LLC</td>
      </tr>
    </tbody>
  </table >
)



export default InquiryMail
